import React from 'react';
import { InferGetStaticPropsType } from 'next';
import Link from 'next/link';
import cx from 'classnames';

import styles from '../styles/Home.module.scss';
import Layout from '../components/Layout';
import { api, RegionSettings } from '../data/api';
import HomeHeader from '../components/HomeHeader';

import OperatorLogo, { OperatorName } from '../components/OperatorLogo';
import ComparisonChartTable from '../components/ComparisonChartTable';
import AboutThisData from '../components/AboutThisData';
import { citiesIndexInfo } from '../data/city-info';

function Home({
    citiesIndexInfo,
    allRegionSettings,
    uniqueOperators,
}: InferGetStaticPropsType<typeof getStaticProps>) {
    return (
        <Layout header={<HomeHeader />} title="Global Micromobility Dashboard">
            <div className={styles.sectionContainer}>
                <section className={cx('shadow-8', styles.headerSection)}>
                    <div className={styles.headerTitleCard}>
                        <h1>Global Micromobility Index</h1>
                        <div className={styles.headerPillContainer}>
                            <div className={styles.headerPill}>
                                {citiesIndexInfo.cityIndexInfos.length} Cities
                                and {Object.keys(allRegionSettings).length}{' '}
                                Regions
                            </div>
                        </div>
                        <div className={styles.headerMetricsContainer}>
                            <div className={styles.headerMetric}>
                                <span className={styles.headerMetricLabel}>
                                    Total Trips
                                </span>
                                <span className={styles.headerMetricValue}>
                                    {citiesIndexInfo.citiesSummaryInfo.totalTrips.toLocaleString()}
                                </span>
                            </div>
                            <div className={styles.headerMetric}>
                                <span className={styles.headerMetricLabel}>
                                    Trips per Day
                                </span>
                                <span className={styles.headerMetricValue}>
                                    {Math.round(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .tripsPerDay
                                    ).toLocaleString()}
                                </span>
                            </div>
                            <div className={styles.headerMetric}>
                                <span className={styles.headerMetricLabel}>
                                    Average Vehicles
                                </span>
                                <span className={styles.headerMetricValue}>
                                    {Math.round(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .avgVehicles
                                    ).toLocaleString()}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.headerSubtext}>
                        <h2 className={styles.infoHeader}>
                            How are shared bikes and scooters moving your city
                            forward?
                        </h2>
                        <p>
                            Now you can easily compare shared micromobility
                            metrics to support your research, inform your
                            community, guide your permit, and build your bike
                            lane campaign. We designed the Index, with the
                            support of agency partners and operators, to help
                            accelerate the future of safe, accessible,
                            low-carbon mobility.
                        </p>
                        <p>
                            Want a micromobility dashboard for your city?{' '}
                            <a
                                className="link"
                                href="https://share.hsforms.com/1-xQU19-ATYymp-fOIU95WQ3i9lh?utm_campaign=Global%20Micromobility%20Index&utm_source=1"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Add your city
                            </a>
                            .
                        </p>
                    </div>
                </section>

                {Object.keys(citiesIndexInfo.cityIndexInfos).length > 0 && (
                    <section>
                        <ComparisonChartTable
                            citiesIndexInfo={citiesIndexInfo}
                        />
                    </section>
                )}

                <section>
                    <div className={styles.regionsContainer}>
                        {Object.keys(allRegionSettings).length > 0 && (
                            <>
                                <h2 className={styles.subheader}>Regions</h2>
                                <ul className={styles.dashboardList}>
                                    {Object.values(allRegionSettings).map(
                                        region => (
                                            <Link
                                                href={`/regions/${region.slug}`}
                                                key={region.slug}
                                                passHref
                                            >
                                                <a
                                                    className={cx(
                                                        'shadow-8',
                                                        styles.dashboardListItem
                                                    )}
                                                >
                                                    {region.organization_logo_url && (
                                                        <img
                                                            alt={`${region.name} logo`}
                                                            src={
                                                                region.organization_logo_url
                                                            }
                                                            className={
                                                                styles.organizationLogo
                                                            }
                                                        />
                                                    )}
                                                    <div
                                                        className={cx(
                                                            'link',
                                                            styles.organizationLink
                                                        )}
                                                    >
                                                        {region.name}
                                                    </div>
                                                </a>
                                            </Link>
                                        )
                                    )}
                                </ul>
                            </>
                        )}
                    </div>
                </section>

                <section>
                    <AboutThisData />
                </section>

                <section>
                    <div className={styles.operatorContainer}>
                        <span className={styles.activeOperatorText}>
                            Our operator partners
                        </span>
                        <div className={styles.operatorLogoContainer}>
                            {/* all operator logos */}
                            {uniqueOperators.map(op => (
                                <div key={op} className={styles.operatorLogo}>
                                    <OperatorLogo
                                        operator={op as OperatorName}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export async function getStaticProps() {
    const allCityInfo = await citiesIndexInfo();
    const allRegionalDashboards = await api.allRegionalSlugs();
    const allRegionSettings: Record<string, RegionSettings> = {};
    for (const region of allRegionalDashboards) {
        const settings = await api.regionSettings(region.slug);
        if (settings != null) {
            allRegionSettings[region.slug] = settings;
        }
    }

    const uniqueOperators = Array.from(
        new Set(
            allCityInfo.cityIndexInfos.flatMap(
                dash => dash.activeOperators as OperatorName[]
            )
        )
    ).sort();

    return {
        props: {
            citiesIndexInfo: allCityInfo,
            allRegionSettings,
            uniqueOperators,
        },
    };
}

export default Home;
