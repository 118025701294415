import React, { useState } from 'react';
import Link from 'next/link';
import styles from '../styles/HomeHeader.module.scss';
import LinearUnitSelector from './LinearUnitSelector';

export default function HomeHeader() {
    return (
        <header className={styles.header}>
            <Link href="/">
                <a rel="noopener">
                    <img
                        alt="Ride Report"
                        src="/logo-on-light.svg"
                        className={styles.logo}
                    />
                </a>
            </Link>
            <LinearUnitSelector></LinearUnitSelector>
        </header>
    );
}
