import React, { useEffect, useState } from 'react';
import Checkbox from './Checkbox';
import styles from '../styles/ComparisonChartTableRow.module.scss';
import NUMBER_FORMATTERS from '../utils/number-format';
import { DashboardData } from '../data/city-info';
import { CitySummaryVehicleClassTimePeriodEntry } from '../data/public-dash-data';
import useTippy from '../utils/use-tippy';
import {
    formatAverageTripsPerDayMetadataMessage,
    formatTotalTripsMetadataMessage,
    getExternalCountForPeriod,
} from '../utils/external-trip-counts';
import { ExternalTripCountsData, VehicleClass, api } from '../data/api';
import { DataBucket } from './metrics/use-metrics';
import { has } from 'core-js/core/dict';

interface ComparisonChartTableRowProps {
    onClick?: () => void;
    href?: string;
    dash: DashboardData;
    dataEntry: CitySummaryVehicleClassTimePeriodEntry | null;
    toggleSelected: () => void;
    isChartRow: boolean;
    isSelected: boolean;
    color: string;
    timePeriod: string;
    vehicleClass: VehicleClass;
    dataBuckets: DataBucket[] | null;
}

function calcPer1kResidentsMetric(count: number | undefined, pop: number) {
    if (!count) {
        return 0;
    }
    return (count / pop) * 1000;
}

export const ComparisonChartTableRow = React.forwardRef(
    function ComparisonChartTableRow(
        {
            onClick,
            href,
            dash,
            dataEntry,
            toggleSelected,
            isSelected,
            isChartRow,
            color,
            timePeriod,
            vehicleClass,
            dataBuckets,
        }: ComparisonChartTableRowProps,
        ref: any
    ) {
        let totalTripsTooltipText: string | null = null;
        let averageTripsTooltipText: string | null = null;
        let [externalTripCounts, setExternalTripCounts] =
            useState<ExternalTripCountsData | null>(null);

        useEffect(() => {
            if (dash.applyExternalTripCounts) {
                api.getExternalTripCounts(dash.slug).then(data =>
                    setExternalTripCounts(data)
                );
            }
        }, [dash.applyExternalTripCounts, dash.slug]);

        const hasExternalTripsForPeriod =
            externalTripCounts &&
            getExternalCountForPeriod(
                timePeriod,
                vehicleClass,
                externalTripCounts.tripCounts
            );

        if (
            dash.applyExternalTripCounts &&
            dataEntry &&
            externalTripCounts &&
            hasExternalTripsForPeriod &&
            dataBuckets
        ) {
            totalTripsTooltipText = formatTotalTripsMetadataMessage(
                dataEntry.totalTrips,
                timePeriod,
                vehicleClass,
                externalTripCounts,
                dash.name,
                dataBuckets,
                dash.slug
            );
            averageTripsTooltipText = formatAverageTripsPerDayMetadataMessage(
                dataEntry.totalTrips,
                timePeriod,
                vehicleClass,
                externalTripCounts,
                dash.name,
                dataBuckets,
                dash.slug,
                dash.population
            );
        }

        const [totalTripsMetricRef] = useTippy<HTMLTableCellElement>(
            totalTripsTooltipText,
            {
                placement: 'bottom',
                allowHTML: true,
                interactive: true,
                appendTo: () => document.body,
            }
        );
        const [averageTripsMetricRef] = useTippy<HTMLTableCellElement>(
            averageTripsTooltipText,
            {
                placement: 'bottom',
                allowHTML: true,
                interactive: true,
                appendTo: () => document.body,
            }
        );
        const asterisk = hasExternalTripsForPeriod ? '*' : '';

        return (
            <tr onClick={onClick} ref={ref}>
                <td>
                    <div className={styles.namePopCellContainer}>
                        <div
                            style={{ pointerEvents: 'auto' }}
                            className={styles.checkboxContainer}
                            onClick={e => e.stopPropagation()}
                        >
                            <Checkbox
                                id={dash.slug}
                                checked={isSelected}
                                onChange={toggleSelected}
                                color={color}
                                disabled={!isChartRow && !dataEntry}
                            />
                        </div>
                        <div className={styles.namePopCellText}>
                            <span className={styles.cityName}>
                                <a href={href}>
                                    <div className="link">{dash.name}</div>
                                </a>
                            </span>
                            <span className={styles.population}>
                                {dash.population.toLocaleString()}
                            </span>
                        </div>
                    </div>
                </td>
                {/* Show No Data message if there's not data for current filter settings */}
                {dataEntry == null ? (
                    <td colSpan={5} className={styles.noDataTd}>
                        No data for current selection(s)
                    </td>
                ) : (
                    <>
                        <td ref={totalTripsMetricRef}>
                            {NUMBER_FORMATTERS.integer.format(
                                dataEntry.totalTrips
                            ) + asterisk}
                        </td>
                        <td ref={averageTripsMetricRef}>
                            {NUMBER_FORMATTERS.float.format(
                                calcPer1kResidentsMetric(
                                    dataEntry.tripsPerDay,
                                    dash.population
                                )
                            ) + asterisk}
                        </td>
                        <td>
                            {NUMBER_FORMATTERS.float.format(
                                calcPer1kResidentsMetric(
                                    dataEntry.avgVehicles,
                                    dash.population
                                )
                            )}
                        </td>
                        <td>
                            {NUMBER_FORMATTERS.float.format(
                                dataEntry.tripsPerVehiclePerDay
                            )}
                        </td>
                        <td>
                            {NUMBER_FORMATTERS.integer.format(
                                dataEntry.avgVehicles
                            )}
                        </td>
                    </>
                )}
            </tr>
        );
    }
);
