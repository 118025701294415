import React, { useState } from 'react';
import { Select, SelectItem } from './Select';
import 'core-js/es/object/from-entries';
import { CitiesIndexInfo } from '../data/city-info';
import {
    ALL_TAGS_KEY,
    PopulationRangeConfig,
    POPULATION_RANGES,
} from './ComparisonChartTable';
import Icon from './Icon';
import styles from '../styles/IndexSearchFilterControl.module.scss';
import cx from 'classnames';

function populationButtonLabelFormatter(): string {
    return 'Population';
}

function tagsButtonLabelFormatter(): string {
    return 'Tags';
}

interface IndexSearchFilterControlProps {
    citiesIndexInfo: CitiesIndexInfo;
    searchText: string;
    setSearchText: (searchText: string) => void;
    populationRange: PopulationRangeConfig;
    setPopulationRange: (populationRange: PopulationRangeConfig) => void;
    tag: string;
    setTag: (tag: string) => void;
}

export default function IndexSearchFilterControl({
    citiesIndexInfo,
    searchText,
    setSearchText,
    populationRange,
    setPopulationRange,
    tag,
    setTag,
}: IndexSearchFilterControlProps) {
    const uniqueTags = Array.from(
        new Set(citiesIndexInfo.cityIndexInfos.flatMap(dash => dash.tags))
    ).sort();
    const tagsPlusAll = uniqueTags.concat(ALL_TAGS_KEY);
    const tagItems: Record<string, SelectItem> = Object.fromEntries(
        tagsPlusAll.map(tag => [
            tag,
            {
                name: tag,
                key: tag,
            } as SelectItem,
        ])
    );

    const populationRangeItems: Record<string, SelectItem> = Object.fromEntries(
        Object.keys(POPULATION_RANGES).map(key => [
            key,
            {
                name: POPULATION_RANGES[key].label,
                key: key,
            } as SelectItem,
        ])
    );

    function getFilterSettingsForDisplay(): string[] {
        let filterSettings: string[] = [];
        if (tag !== ALL_TAGS_KEY) {
            filterSettings.push(`${tag} only`);
        }
        if (populationRange.label !== POPULATION_RANGES['all'].label) {
            filterSettings.push(`Population: ${populationRange.label}`);
        }
        return filterSettings;
    }

    const [filterDivIsExpanded, setFilterDivIsExpanded] = useState(false);

    function removeFilterSetting(filterText: string): void {
        if (filterText.includes('Population')) {
            setPopulationRange(POPULATION_RANGES['all']);
        } else if (filterText.includes('only')) {
            setTag('All');
        }
    }

    function resetAllFilters() {
        setPopulationRange(POPULATION_RANGES['all']);
        setTag('All');
    }

    return (
        <div>
            <div
                className={cx(
                    styles.filterContainer,
                    filterDivIsExpanded ? styles.backgrounded : null
                )}
            >
                <div className={styles.searchBar}>
                    <Icon className={styles.searchBarIcon} icon="Search" />
                    <input
                        className={styles.searchBarInput}
                        type="text"
                        value={searchText}
                        aria-label="Search for a city"
                        onChange={e => setSearchText(e.target.value)}
                    />
                    <div
                        className={cx(styles.inputUnderline, 'inputUnderline')}
                    ></div>
                    {searchText !== '' && (
                        <button
                            className={styles.searchBarClearButton}
                            onClick={e => setSearchText('')}
                        >
                            <Icon
                                className={styles.searchBarClearIcon}
                                icon="Close"
                            />
                        </button>
                    )}
                </div>
                {!filterDivIsExpanded && (
                    <div className={styles.rightSideFilterControls}>
                        <div>
                            <button
                                className={cx(styles.filterButton, 'shadow-8')}
                                onClick={e => setFilterDivIsExpanded(true)}
                            >
                                <span className={styles.filterTextSpan}>
                                    Filter
                                </span>
                                <Icon
                                    className={styles.filterBarIcon}
                                    icon="Filter"
                                />
                            </button>
                        </div>
                    </div>
                )}
                {filterDivIsExpanded && (
                    <div className={styles.filtersPanel}>
                        <div
                            className={cx(
                                styles.filterPanelDropdown,
                                'shadow-8'
                            )}
                        >
                            <Select
                                dropdownSide="right"
                                items={populationRangeItems}
                                onChange={key =>
                                    setPopulationRange(POPULATION_RANGES[key])
                                }
                                selectedItemKey={populationRange.key as string}
                                buttonLabelFunction={
                                    populationButtonLabelFormatter
                                }
                                additionalClassForDropdownContainer={
                                    styles.additionalClassForDropdownContainer
                                }
                                additionalClassForDropdownOpen={
                                    styles.indexSearchFilterDropdownOpen
                                }
                            />
                        </div>
                        <div
                            className={cx(
                                styles.filterPanelDropdown,
                                'shadow-8'
                            )}
                        >
                            <Select
                                dropdownSide="right"
                                items={tagItems}
                                onChange={tag => setTag(tag)}
                                selectedItemKey={tag}
                                buttonLabelFunction={tagsButtonLabelFormatter}
                                additionalClassForDropdownContainer={
                                    styles.additionalClassForDropdownContainer
                                }
                                additionalClassForDropdownOpen={
                                    styles.indexSearchFilterDropdownOpen
                                }
                            />
                        </div>
                        <div>
                            <button
                                className={cx(styles.closeButton, 'shadow-8')}
                                onClick={e => setFilterDivIsExpanded(false)}
                            >
                                <span>Close</span>
                                <Icon
                                    className={styles.filterBarIcon}
                                    icon="Close"
                                />
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className={styles.displayFilterSettingsContainer}>
                {getFilterSettingsForDisplay().map(setting => {
                    return (
                        <div
                            key={setting}
                            className={styles.filterSettingDisplay}
                        >
                            <span className={styles.filterSettingDisplaySpan}>
                                {setting}
                            </span>
                            <button
                                className={styles.filterSettingDisplayButton}
                                onClick={e => removeFilterSetting(setting)}
                            >
                                <Icon
                                    className={styles.filterSettingDisplayX}
                                    icon="Close"
                                />
                            </button>
                        </div>
                    );
                })}
                {getFilterSettingsForDisplay().length > 0 && (
                    <button
                        className={styles.resetAllFiltersButton}
                        onClick={e => resetAllFilters()}
                    >
                        <Icon
                            className={styles.resetAllFiltersIcon}
                            icon="Reset-Filters"
                        />
                    </button>
                )}
            </div>
        </div>
    );
}
