import cx from 'classnames';
import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import {
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { formatTickOnXAxis, formatTickOnYAxis } from './chart/util';
import Loading from './Loading';
import { MetricsBySlug, MetricsData } from './metrics/use-metrics';
import styles from '../styles/ComparisonChart.module.scss';
import { CHART_ANIMATION_DURATION } from './chart/constants';
import { LinearUnitsInfo, useLinearUnitsInfo } from '../utils/use-linear-units';
import { convertLinearUnitsForDisplay } from '../utils/units-conversion';
import { DashboardData } from '../data/city-info';

type ComparisonMetric = 'tripsPerDay' | 'tripDistancePerDay';

function useChartData(
    comparisonMetric: ComparisonMetric,
    metrics: MetricsBySlug | null,
    linearUnits: LinearUnitsInfo
) {
    return useMemo(() => {
        if (metrics == null) return [];
        const rollingAverageDuration = 14;
        const chartData: Record<string, { [slug: string]: number }> = {};

        for (let [slug, metric] of Object.entries(metrics)) {
            if (metric == null) continue;

            let data = metric[comparisonMetric].dataBuckets;
            // ensure all distance measures are in the user-specified linear units
            if (
                (metric[comparisonMetric].yUnit === 'kilometer' ||
                    metric[comparisonMetric].yUnit === 'miles') &&
                metric[comparisonMetric].yUnit !== linearUnits.units
            ) {
                const metricNotNull = metric as MetricsData; // We know metric isn't null at this point
                data = data.map(datum => ({
                    ...datum,
                    yValue: convertLinearUnitsForDisplay(
                        Number(datum.yValue),
                        metricNotNull[comparisonMetric].yUnit,
                        linearUnits
                    ),
                }));
            }

            data.forEach((dayOfData, i) => {
                const daysIncluded = data
                    .slice(Math.max(i - rollingAverageDuration + 1, 0), i + 1)
                    .map(dayOfData => dayOfData.yValue);
                const avgOfDays =
                    daysIncluded.reduce((total, value) => total + value, 0) /
                    daysIncluded.length;

                chartData[dayOfData.xValue] = {
                    ...chartData[dayOfData.xValue],
                    [slug]: avgOfDays,
                };
            });
        }

        // we use __date so it doesn't ever conflict with a city slug
        const sortedData = sortBy(
            Object.entries(chartData).map(([date, values]) => ({
                ...values,
                __date: date,
            })),
            '__date'
        );

        return sortedData;
    }, [comparisonMetric, metrics, linearUnits]);
}

interface ComparisonChartProps {
    cityInfo: (DashboardData & { color: string })[];
    metrics: MetricsBySlug | null;
}

function ComparisonChart({ cityInfo, metrics }: ComparisonChartProps) {
    const [comparisonMetric, setComparisonMetric] =
        useState<ComparisonMetric>('tripsPerDay');
    const linearUnits = useLinearUnitsInfo();

    const chartData = useChartData(comparisonMetric, metrics, linearUnits);

    const [slugHovered, setSlugHovered] = useState<string | null>(null);

    const slugs = Object.keys(metrics ?? {});

    const startDate =
        chartData.length > 0 ? DateTime.fromISO(chartData[0].__date) : null;
    const endDate =
        chartData.length > 0
            ? DateTime.fromISO(chartData[chartData.length - 1].__date)
            : null;
    const totalDays =
        startDate && endDate ? endDate.diff(startDate).as('days') : 0;

    return (
        <div>
            <div className={styles.controlsContainer}>
                <div className={styles.controls}>
                    <button
                        type="button"
                        className={cx(
                            comparisonMetric === 'tripsPerDay' &&
                                styles.isActive
                        )}
                        onClick={() => setComparisonMetric('tripsPerDay')}
                    >
                        Trips per Day
                    </button>
                    <button
                        type="button"
                        className={cx(
                            comparisonMetric === 'tripDistancePerDay' &&
                                styles.isActive
                        )}
                        onClick={() =>
                            setComparisonMetric('tripDistancePerDay')
                        }
                    >
                        Distance per Day
                    </button>
                </div>
                <span className={styles.subtext}>
                    * 14-day rolling average
                    {comparisonMetric === 'tripDistancePerDay'
                        ? `, in ${linearUnits.units}`
                        : ''}
                </span>
            </div>
            <div className={styles.chartWrapperOuter}>
                <div className={styles.chartWrapperInner}>
                    <Loading loading={metrics == null} />
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
                            data={chartData}
                        >
                            <XAxis
                                dataKey="__date"
                                tickFormatter={formatTickOnXAxis(totalDays)}
                                interval={0}
                                axisLine={false}
                                tickLine={false}
                                style={{
                                    fontSize: '0.75em',
                                }}
                            >
                                <Label
                                    value="© Ride Report"
                                    position="insideTopRight"
                                    fill="#667380"
                                    dy={-20}
                                    style={{
                                        fontSize: '0.85em',
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                tickFormatter={formatTickOnYAxis}
                                axisLine={false}
                                tickLine={false}
                                width={46}
                                domain={[0, 'auto']}
                                style={{
                                    fontSize: '0.75em',
                                }}
                            />
                            <CartesianGrid stroke="#ebeef0" vertical={false} />
                            <Tooltip<string | number, string>
                                animationDuration={CHART_ANIMATION_DURATION}
                                labelFormatter={(date: string) =>
                                    DateTime.fromISO(date).toLocaleString(
                                        DateTime.DATE_MED_WITH_WEEKDAY
                                    )
                                }
                                formatter={(value: number, slug: string) => [
                                    value.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    }),
                                    cityInfo.find(city => city.slug == slug)
                                        ?.name ?? '',
                                ]}
                            />
                            <Legend
                                onMouseEnter={props =>
                                    setSlugHovered(props.dataKey)
                                }
                                onMouseLeave={() => setSlugHovered(null)}
                                formatter={value =>
                                    cityInfo.find(city => city.slug == value)
                                        ?.name
                                }
                            />
                            {slugs.map(slug => (
                                <Line
                                    animationDuration={CHART_ANIMATION_DURATION}
                                    key={slug}
                                    dataKey={slug}
                                    stroke={
                                        slugHovered == null ||
                                        slugHovered === slug
                                            ? cityInfo.find(
                                                  c => c.slug === slug
                                              )?.color
                                            : '#667380'
                                    }
                                    opacity={
                                        slugHovered == null
                                            ? 0.5
                                            : slugHovered === slug
                                            ? 1
                                            : 0.2
                                    }
                                    strokeWidth={2}
                                    dot={false}
                                />
                            ))}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

export default ComparisonChart;
