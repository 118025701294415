import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash-es';
import Link from 'next/link';
import {
    MetricsBySlug,
    useMetricsForMultipleCities,
} from './metrics/use-metrics';
import ComparisonChart from './ComparisonChart';
import ColumnHeader from './ColumnHeader';
import TableSection from './TableSection';
import styles from '../styles/ComparisonChartTable.module.scss';
import NUMBER_FORMATTERS from '../utils/number-format';
import { CitiesIndexInfo, DashboardData } from '../data/city-info';
import DATA_COLORS from '../assets/data-colors.json';
import cx from 'classnames';
import { VehicleClass } from '../data/api';
import { ComparisonChartTableRow } from './ComparisonChartTableRow';
import IndexDataSubsetFilterControl from './IndexDataSubsetFilterControl';
import { useQueryState } from '../utils/use-query-state';
import IndexSearchFilterControl from './IndexSearchFilterControl';
import {
    CitySummaryVehicleClassTimePeriodEntry,
    VehicleTimePeriodSummaries,
} from '../data/public-dash-data';

const COMPARISON_LIMIT = 5;

const COMPARISON_COLORS = DATA_COLORS.slice(0, 5).map(
    colorObj => colorObj.background
);

type PopulationRangeKey =
    | 'all'
    | 'verySmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'veryLarge'
    | 'jumbo';

export type PopulationRangeConfig = {
    key: PopulationRangeKey;
    label: string;
    low: number;
    high: number;
};

export const ALL_TAGS_KEY = 'All';
const allPopulationsKey = 'all';

export const POPULATION_RANGES: Record<
    PopulationRangeKey,
    PopulationRangeConfig
> = {
    all: { key: 'all', label: 'All', low: 0, high: 999999999 },
    verySmall: { key: 'verySmall', label: '< 25,000', low: 0, high: 24999 },
    small: { key: 'small', label: '25,000 - 99,999', low: 25000, high: 99999 },
    medium: {
        key: 'medium',
        label: '100,000 - 249,999',
        low: 100000,
        high: 249999,
    },
    large: {
        key: 'large',
        label: '250,000 - 999,999',
        low: 250000,
        high: 999999,
    },
    veryLarge: {
        key: 'veryLarge',
        label: '1,000,000 - 2,500,000',
        low: 1000000,
        high: 2500000,
    },
    jumbo: {
        key: 'jumbo',
        label: '> 2,500,000',
        low: 2500001,
        high: 999999999,
    },
};

type Column =
    | 'name'
    | 'totalTrips'
    | 'tripsPerDayPer1k'
    | 'vehiclesPer1k'
    | 'tripsPerVehiclePerDay'
    | 'avgVehicles';
type Sorting = [column: Column, order: 'asc' | 'desc'];

interface ComparisonChartTableProps {
    citiesIndexInfo: CitiesIndexInfo;
}

interface getFilteredDashboardsConfig {
    allDashboards: DashboardData[];
    searchText: string;
    selectedCities: string[];
    populationRange: PopulationRangeConfig;
    tag: string;
}

function getFilteredDashboards(
    config: getFilteredDashboardsConfig
): DashboardData[] {
    const filteredDashboards = config.allDashboards
        // filter out selected cities since they're shown above
        .filter(dash => !config.selectedCities.includes(dash.slug))
        // filter remaining results based on search
        .filter(
            dash =>
                dash.name
                    .toLocaleLowerCase()
                    .includes(config.searchText.toLocaleLowerCase()) ||
                dash.slug
                    .toLocaleLowerCase()
                    .includes(config.searchText.toLocaleLowerCase())
        )
        // filter based on population
        .filter(
            dash =>
                config.populationRange.key === allPopulationsKey ||
                (dash.population >= config.populationRange.low &&
                    dash.population <= config.populationRange.high)
        )
        // filter based on tag if one is set
        .filter(
            dash =>
                config.tag === ALL_TAGS_KEY || dash.tags.includes(config.tag)
        );
    return filteredDashboards;
}

function ComparisonChartTable({ citiesIndexInfo }: ComparisonChartTableProps) {
    const [timePeriod, setTimePeriod] = useQueryState('time', 'all');
    const [vehicleClass, setVehicleClass] = useQueryState('vehicle', 'all');
    const [searchText, setSearchText] = useState('');
    const [populationRange, setPopulationRange] =
        useState<PopulationRangeConfig>(POPULATION_RANGES[allPopulationsKey]);
    const [tag, setTag] = useState(ALL_TAGS_KEY);

    const [[sortColumn, sortOrder], setSorting] = useState<Sorting>([
        'tripsPerDayPer1k',
        'desc',
    ]);

    const allDashboards = citiesIndexInfo.cityIndexInfos;

    const [selectedCities, setSelectedCities] = useState<string[]>([]);

    const [filteredDashboards, setFilteredDashboards] =
        useState<DashboardData[]>(allDashboards);

    useEffect(() => {
        const newFilteredDashboards = getFilteredDashboards({
            allDashboards: allDashboards,
            searchText: searchText,
            selectedCities: selectedCities,
            populationRange: populationRange,
            tag: tag,
        });
        setFilteredDashboards(newFilteredDashboards);
    }, [allDashboards, searchText, selectedCities, populationRange, tag]);

    // Using a separate useEffect to avoid re-filtering data when sorting is changed
    const [filteredAndSortedDashboards, setFilteredAndSortedDashboards] =
        useState<DashboardData[]>([]);

    // Set to true on initial load so the top two selected cities will be selected after list is filtered by any queries in URl
    const [initialCitiesSelectionNeeded, setInitialCitiesSelectionNeeded] =
        useState<boolean>(true);

    useEffect(() => {
        // An iteratee to sort data by sort and filter settings
        function sortIteratee(dashboard: DashboardData) {
            if (sortColumn === 'name') {
                return dashboard.name;
            }
            return (
                dashboard.vehicleTimePeriodSummaries[vehicleClass]?.[
                    timePeriod
                ]?.[sortColumn] ?? -1
            );
        }

        const newFilteredAndSortedDashboards = orderBy(
            filteredDashboards,
            sortIteratee,
            sortOrder
        );
        setFilteredAndSortedDashboards(newFilteredAndSortedDashboards);
    }, [filteredDashboards, sortColumn, sortOrder, timePeriod, vehicleClass]);

    useEffect(() => {
        if (initialCitiesSelectionNeeded) {
            setSelectedCities(['dc', 'denver']);
            setInitialCitiesSelectionNeeded(false);
        }
    }, [initialCitiesSelectionNeeded]);

    function filterVehicleTimePeriodSummaries(
        vtps: VehicleTimePeriodSummaries
    ): CitySummaryVehicleClassTimePeriodEntry | null {
        // Return the correct CitySummaryVehicleClass entry for a given city based on current filter settings, or null
        return vtps[vehicleClass]?.[timePeriod] ?? null;
    }

    const handleToggleSelectedCity = useCallback(
        (slug: string) => {
            if (selectedCities.includes(slug)) {
                setSelectedCities([
                    ...selectedCities.filter(sc => sc !== slug),
                ]);
            } else {
                // can only compare so many cities at a time
                if (selectedCities.length >= COMPARISON_LIMIT) return;
                setSelectedCities([...selectedCities, slug]);
            }
        },
        [selectedCities, setSelectedCities]
    );

    // Also fetch trip metrics for mapviews with external trips
    // We'll need the dataBuckets to calculate average trips per day including external trips
    const citiesToFetch = useMemo(() => {
        const mapviewsWithExternalTrips = Object.values(
            citiesIndexInfo.cityIndexInfos
        )
            .filter(dash => dash.applyExternalTripCounts)
            .map(dash => dash.slug);
        return selectedCities.concat(...mapviewsWithExternalTrips);
    }, [selectedCities, citiesIndexInfo.cityIndexInfos]);
    // Only fetch this fine-grained data for the cities shown in the comparison chart
    const fetchedMetrics = useMetricsForMultipleCities(
        citiesToFetch,
        timePeriod,
        vehicleClass as VehicleClass,
        citiesIndexInfo
    );
    let metricsForComparison: MetricsBySlug | null = null;
    if (fetchedMetrics !== null) {
        const metricsForComparisonDict = {};
        Object.keys(fetchedMetrics).forEach(slug => {
            if (selectedCities.includes(slug)) {
                metricsForComparisonDict[slug] = fetchedMetrics[slug];
            }
        });
        metricsForComparison = metricsForComparisonDict;
    }

    const headerProps = {
        setSorting,
        sorting: [sortColumn, sortOrder] as const,
    };
    return (
        <div className={styles.container}>
            <TableSection>
                <h2 className={styles.subheader}>Shared Mobility Index</h2>
                <IndexDataSubsetFilterControl
                    citiesIndexInfo={citiesIndexInfo}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    vehicleClass={vehicleClass}
                    setVehicleClass={setVehicleClass}
                />
                <ComparisonChart
                    metrics={metricsForComparison}
                    cityInfo={selectedCities.map((slug, index) => {
                        return {
                            ...allDashboards.find(d => d.slug === slug)!,
                            color: COMPARISON_COLORS[index],
                        };
                    })}
                />

                <div className={cx(styles.indexSearchFilterControlContainer)}>
                    <IndexSearchFilterControl
                        citiesIndexInfo={citiesIndexInfo}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        populationRange={populationRange}
                        setPopulationRange={setPopulationRange}
                        tag={tag}
                        setTag={setTag}
                    />
                </div>
                <div className={styles.scrollableTable}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <ColumnHeader<Column>
                                    column="name"
                                    {...headerProps}
                                    text="City"
                                    secondaryText="Population"
                                    tooltipText="Population data is from Census Reporter, Australian Bureau of Statistics and NZ Stats."
                                />
                                <ColumnHeader<Column>
                                    column="totalTrips"
                                    {...headerProps}
                                    text="Trips"
                                    secondaryText="total"
                                    tooltipText="Total shared bike and/or scooter trips for the time period selected."
                                />
                                <ColumnHeader<Column>
                                    column="tripsPerDayPer1k"
                                    {...headerProps}
                                    text="Trips per Day"
                                    secondaryText="per 1k"
                                    tooltipText="Average daily shared bike and/or scooter trips for the time period selected, divided by the program area’s population divided by 1,000. This metric controls for population when comparing between cities."
                                />
                                <ColumnHeader<Column>
                                    column="vehiclesPer1k"
                                    {...headerProps}
                                    text="Vehicles"
                                    secondaryText="per 1k"
                                    tooltipText="Average shared bike and/or scooter vehicles available for rent for the time period selected, divided by the program area’s population divided by 1,000. In other words, for every 1,000 residents in a service area, how many vehicles would be available for rent at any given moment. This metric is an indicator of service level that controls for population when comparing across cities."
                                />
                                <ColumnHeader<Column>
                                    column="tripsPerVehiclePerDay"
                                    {...headerProps}
                                    text="TVD"
                                    secondaryText="avg"
                                    tooltipText="Total shared bike and/or scooter trips divided by the average vehicles available for rent divided by the number of days. This metric is most commonly used as an indicator of how often vehicles are being used."
                                />
                                <ColumnHeader<Column>
                                    column="avgVehicles"
                                    {...headerProps}
                                    text="Vehicles"
                                    secondaryText="avg"
                                    tooltipText="Average number of active vehicles by city. Active vehicles refer to vehicles that are available for rent or rented."
                                />
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                className={cx(styles.bold, styles.allCitiesRow)}
                            >
                                <td>
                                    <span className={styles.regionName}>
                                        Across all cities
                                    </span>
                                </td>
                                <td>
                                    {NUMBER_FORMATTERS.integer.format(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .totalTrips
                                    )}
                                </td>
                                <td>
                                    {NUMBER_FORMATTERS.float.format(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .tripsPerDayPer1k
                                    )}
                                </td>
                                <td>
                                    {NUMBER_FORMATTERS.float.format(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .vehiclesPer1k
                                    )}
                                </td>
                                <td>
                                    {NUMBER_FORMATTERS.float.format(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .tripsPerVehiclePerDay
                                    )}
                                </td>
                                <td>
                                    {NUMBER_FORMATTERS.integer.format(
                                        citiesIndexInfo.citiesSummaryInfo
                                            .avgVehicles
                                    )}
                                </td>
                            </tr>

                            {/* show selected cities fixed to the top of the table */}
                            {selectedCities.map((slug, index) => {
                                const dash = allDashboards.find(
                                    d => d.slug === slug
                                );
                                if (dash == null) return null;
                                return (
                                    <Link
                                        href={`/${dash.slug}`}
                                        key={dash.slug}
                                        passHref
                                    >
                                        <ComparisonChartTableRow
                                            dash={dash}
                                            dataEntry={filterVehicleTimePeriodSummaries(
                                                dash.vehicleTimePeriodSummaries
                                            )}
                                            timePeriod={timePeriod}
                                            vehicleClass={
                                                vehicleClass as VehicleClass
                                            }
                                            toggleSelected={() =>
                                                handleToggleSelectedCity(
                                                    dash.slug
                                                )
                                            }
                                            isSelected={selectedCities.includes(
                                                dash.slug
                                            )}
                                            isChartRow={true}
                                            color={COMPARISON_COLORS[index]}
                                            dataBuckets={
                                                fetchedMetrics?.[dash.slug]
                                                    ?.tripsPerDay.dataBuckets ??
                                                null
                                            }
                                        />
                                    </Link>
                                );
                            })}

                            <tr className={styles.dividerRow}>
                                <td className={styles.dividerCell}></td>
                                <td className={styles.dividerCell}></td>
                                <td className={styles.dividerCell}></td>
                                <td className={styles.dividerCell}></td>
                                <td className={styles.dividerCell}></td>
                                <td className={styles.dividerCell}></td>
                            </tr>
                            {filteredAndSortedDashboards.length > 0 &&
                                filteredAndSortedDashboards.map(dash => {
                                    return (
                                        <Link
                                            href={`/${dash.slug}`}
                                            key={dash.slug}
                                            passHref
                                        >
                                            <ComparisonChartTableRow
                                                dash={dash}
                                                dataEntry={filterVehicleTimePeriodSummaries(
                                                    dash.vehicleTimePeriodSummaries
                                                )}
                                                toggleSelected={() =>
                                                    handleToggleSelectedCity(
                                                        dash.slug
                                                    )
                                                }
                                                isSelected={selectedCities.includes(
                                                    dash.slug
                                                )}
                                                isChartRow={false}
                                                color="#8797a6"
                                                timePeriod={timePeriod}
                                                vehicleClass={
                                                    vehicleClass as VehicleClass
                                                }
                                                dataBuckets={
                                                    fetchedMetrics?.[dash.slug]
                                                        ?.tripsPerDay
                                                        .dataBuckets ?? null
                                                }
                                            />
                                        </Link>
                                    );
                                })}
                            {filteredAndSortedDashboards.length === 0 && (
                                <tr>
                                    <td
                                        colSpan={6}
                                        className={styles.noCitiesRow}
                                    >
                                        No data for current selection(s)
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={styles.ctaContainer}>
                    <a
                        className="button"
                        href="https://share.hsforms.com/1-xQU19-ATYymp-fOIU95WQ3i9lh?utm_campaign=Global%20Micromobility%20Index&utm_source=2"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Add your city
                    </a>
                </div>
            </TableSection>
        </div>
    );
}

export default ComparisonChartTable;
