import Icon from './Icon';

import styles from '../styles/Checkbox.module.scss';

interface CheckboxProps {
    id: string;
    checked: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
    label?: React.ReactNode;
    ariaLabel?: string;
    color?: string;
}

function Checkbox({
    id,
    checked,
    disabled,
    onChange,
    label,
    ariaLabel,
    color,
}: CheckboxProps) {
    return (
        <div
            className={styles.checkboxWrapper}
            style={
                color
                    ? ({
                          '--ride-report-inactive-toggle-color': color,
                          '--ride-report-active-toggle-color': color,
                          '--ride-report-hover-toggle-color': color,
                      } as React.CSSProperties) // css variables aren't included in CSSProperties by default
                    : undefined
            }
        >
            <input
                className={styles.checkbox}
                type="checkbox"
                id={id}
                checked={checked}
                onChange={e => onChange(e.target.checked)}
                disabled={disabled}
            />
            <label aria-label={ariaLabel} htmlFor={id}>
                <div>
                    <Icon icon="Check" />
                </div>
                {label}
            </label>
        </div>
    );
}

export default Checkbox;
