import React from 'react';
import { Select, SelectItem } from './Select';
import { CitiesIndexInfo } from '../data/city-info';
import styles from '../styles/IndexDataSubsetFilterControl.module.scss';
import cx from 'classnames';
import { VEHICLE_CLASSES } from './DataSelector';

interface IndexDataSubsetFilterControlProps {
    citiesIndexInfo: CitiesIndexInfo;
    timePeriod: string;
    setTimePeriod: (timePeriod: string) => void;
    vehicleClass: string;
    setVehicleClass: (vehicleClass: string) => void;
}

export default function IndexDataSubsetFilterControl({
    citiesIndexInfo,
    timePeriod,
    setTimePeriod,
    vehicleClass,
    setVehicleClass,
}: IndexDataSubsetFilterControlProps) {
    const timePeriodItems: Record<string, SelectItem> = Object.fromEntries(
        citiesIndexInfo.citiesSummaryInfo.allTimePeriods
            .map(tp => {
                const name = tp === 'all' ? 'All Time' : tp;
                return [tp, { key: tp, name: name }];
            })
            .sort((a, b) => {
                // Sort so All Time comes first, then chronologically descending
                if (a[0] == 'all') {
                    return -1;
                }
                return a < b ? 1 : -1;
            })
    );

    const vehicleClassItems = Object.fromEntries(
        citiesIndexInfo.citiesSummaryInfo.allVehicleClasses.map(vc => [
            vc,
            {
                ...(VEHICLE_CLASSES[vc] as SelectItem),
            },
        ])
    );

    return (
        <div className={styles.rightSideFilterControls}>
            <div className={cx(styles.filterSelectControl, 'shadow-8')}>
                <Select
                    dropdownSide="left"
                    items={vehicleClassItems}
                    onChange={setVehicleClass}
                    selectedItemKey={vehicleClass}
                />
            </div>
            <div className={cx(styles.filterSelectControl, 'shadow-8')}>
                <Select
                    dropdownSide="left"
                    icon="Calendar"
                    items={timePeriodItems}
                    onChange={setTimePeriod}
                    selectedItemKey={timePeriod}
                />
            </div>
        </div>
    );
}
