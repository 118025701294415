import ErrorBoundary from './ErrorBoundary';
import styles from '../styles/TableSection.module.scss';

type TableSectionProps = {
    children: React.ReactNode;
};

function TableSection({ children }: TableSectionProps) {
    return (
        <section className={styles.tableSection}>
            <ErrorBoundary>{children}</ErrorBoundary>
        </section>
    );
}

export default TableSection;
